import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 976.000000 962.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,962.000000) scale(0.100000,-0.100000)">

<path d="M5015 8593 c-274 -9 -601 -57 -853 -124 -125 -33 -312 -92 -312 -99
0 -3 24 -29 53 -58 l53 -52 155 44 c249 71 627 136 796 136 34 0 46 8 113 75
41 41 72 76 69 77 -2 2 -35 2 -74 1z"/>
<path d="M5046 8519 c-36 -39 -66 -71 -66 -72 0 -1 72 -2 160 -2 88 0 160 2
160 5 0 3 -31 36 -70 73 -46 44 -78 67 -93 67 -17 0 -44 -22 -91 -71z"/>
<path d="M5260 8517 l75 -75 91 -6 c254 -17 553 -73 784 -146 52 -16 100 -30
105 -30 12 0 115 97 115 109 0 8 -162 59 -340 106 -222 59 -510 101 -750 111
l-155 6 75 -75z"/>
<path d="M3659 8301 c-258 -105 -486 -227 -724 -390 -60 -41 -112 -76 -115
-78 -2 -2 20 -27 49 -55 62 -59 38 -64 198 43 146 98 261 164 429 245 143 69
391 174 413 174 5 0 12 4 15 9 7 10 -90 111 -107 111 -7 0 -78 -27 -158 -59z"/>
<path d="M6401 8306 l-54 -55 34 -15 c19 -8 88 -36 154 -62 233 -90 540 -256
729 -391 l89 -64 53 52 c29 29 51 56 48 60 -19 32 -438 287 -584 357 -153 72
-390 172 -410 172 -3 0 -30 -24 -59 -54z"/>
<path d="M2772 7797 c-17 -12 -192 -183 -389 -380 l-357 -357 54 -56 55 -55
25 32 c177 231 455 506 688 683 34 26 62 50 61 54 -1 4 -25 28 -53 54 l-52 47
-32 -22z"/>
<path d="M7416 7761 l-48 -48 134 -108 c197 -160 519 -489 621 -635 l17 -25
57 57 57 57 -300 302 c-299 302 -456 449 -478 449 -7 0 -33 -22 -60 -49z"/>
<path d="M4525 7744 c-137 -15 -273 -37 -355 -58 -99 -25 -218 -64 -235 -76
-5 -4 -23 -10 -39 -14 -71 -17 -390 -190 -446 -242 -8 -8 -49 -40 -90 -73
-175 -138 -379 -373 -491 -567 -96 -166 -186 -390 -227 -569 -70 -297 -72
-657 -7 -944 28 -119 76 -266 93 -279 9 -6 11 -12 4 -12 -13 0 7 -51 78 -192
278 -557 812 -968 1424 -1098 293 -62 648 -58 918 10 132 34 252 73 260 84 4
6 8 8 8 4 0 -10 131 47 240 105 103 55 183 106 271 173 l55 42 487 -486 487
-487 0 1711 0 1711 -535 537 -535 536 -175 0 -175 0 -85 35 c-75 31 -311 105
-336 105 -5 0 -9 -8 -10 -17 0 -15 -2 -14 -9 3 -7 18 -24 24 -116 38 -105 16
-381 28 -459 20z m-8 -101 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0
-35z m-570 -275 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m66 15
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-130 -10 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-256 -19 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m330 -126 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m10 -120 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-590 -40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m600 -90 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-590 -20 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m1040 -134 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-124 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z m-136 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-380 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1002 -139
l144 -145 481 -2 481 -3 1 -897 c0 -494 -3 -898 -6 -898 -3 0 -76 61 -163 135
-86 74 -158 135 -161 135 -3 0 -50 -45 -106 -100 -55 -55 -105 -100 -110 -100
-6 0 -10 -7 -10 -16 0 -8 -4 -13 -9 -10 -5 3 -41 -14 -81 -38 -164 -101 -324
-147 -541 -153 -171 -6 -252 5 -404 53 -317 101 -597 385 -703 714 -65 201
-69 478 -10 670 17 56 72 195 79 200 3 3 14 21 24 40 46 89 166 233 255 304
146 116 282 186 441 224 108 27 122 29 194 31 l60 1 144 -145z m-1512 99 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m720 -16 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-850 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m260 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m10 -140 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-580
-160 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m600 -100 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-590 -40 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m600 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-590 -40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m586 5 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-266 -19
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-270 -20 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-114 -11 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z m-126 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m200 -76 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m10 -130 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m10 -130
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m600 -90 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-24 -105 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m-110 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5
17 0 24 -2 18 -5z m-140 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z m284 -15 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-414 5
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-126 -9 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-130 -10 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-119 -11 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5
19 0 27 -2 18 -5z m209 -125 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m600 -90 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-580
-160 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m600 -90 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-590 -50 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m680 -4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-124 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-386 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-134 -11
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m564 -25 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-590 -40 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m600 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-590 -50 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m600 -95 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-590 -15 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1190 -70 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-590 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m1986 15 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m-126 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-80
-56 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-690 -4 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m100 -26 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-730 -24 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-130 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m1460 -66 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-590 -20
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-1180 -60 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1780 -30 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-590 -45 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2
-10 2 -26 0 -35z m-590 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-590 -20 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1780
-40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-590 -35 c-3 -10
-5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-590 -35 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m-590 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m1780 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-590 -35 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m676 0
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1266 -25 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1136 15 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m-766 -59 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-130 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m950 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1080 6
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m490 -56 c-2 -13 -4 -3
-4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-620 46 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-370 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m1000 -131 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z"/>
<path d="M2377 7451 c-100 -99 -179 -182 -176 -185 3 -3 88 78 189 179 102
102 181 185 176 185 -4 0 -90 -81 -189 -179z"/>
<path d="M7890 7446 c63 -64 117 -116 119 -116 13 0 -188 209 -214 222 -11 6
32 -42 95 -106z"/>
<path d="M1980 6989 c-19 -28 -40 -56 -45 -62 -24 -27 -132 -197 -191 -299
-104 -179 -264 -526 -264 -571 0 -13 90 -107 103 -107 4 0 7 6 7 14 0 23 86
220 161 368 92 181 167 308 272 460 45 65 86 125 92 133 7 11 -2 27 -40 65
-27 27 -52 50 -55 50 -3 0 -21 -23 -40 -51z"/>
<path d="M8197 6982 l-47 -47 78 -110 c164 -232 335 -546 433 -795 18 -47 35
-87 36 -89 2 -3 17 12 34 32 17 20 40 42 52 48 12 6 -1 -13 -30 -42 l-52 -54
44 -130 c84 -250 149 -541 174 -785 7 -58 14 -120 16 -139 3 -25 19 -50 57
-89 29 -30 43 -49 31 -43 -12 6 -36 28 -53 48 l-31 38 2 -93 c2 -51 6 -95 10
-98 4 -2 28 19 53 47 52 58 51 46 35 259 -25 356 -111 779 -214 1047 -105 275
-161 398 -268 593 -98 179 -280 450 -302 450 -6 0 -32 -21 -58 -48z"/>
<path d="M1415 5885 c-89 -277 -142 -524 -169 -803 -17 -167 -25 -352 -15
-352 13 0 109 105 109 120 0 44 42 360 60 455 29 147 81 347 131 501 l41 126
-48 49 c-27 27 -51 49 -55 49 -4 0 -28 -65 -54 -145z"/>
<path d="M1293 4769 c-24 -22 -43 -45 -43 -51 0 -11 69 -83 74 -77 4 5 16 169
13 169 -1 0 -21 -19 -44 -41z"/>
<path d="M1226 4618 c12 -333 36 -530 94 -785 47 -210 119 -443 136 -443 7 0
10 -8 7 -18 -10 -38 130 -359 259 -592 109 -198 116 -192 15 11 -81 163 -191
434 -228 567 -7 23 -15 42 -20 42 -4 0 -7 10 -6 23 0 12 -14 76 -31 142 -69
269 -109 559 -118 855 l-6 195 -46 48 c-26 26 -49 47 -53 47 -4 0 -5 -42 -3
-92z"/>
<path d="M8998 4663 l-46 -48 -6 -170 c-13 -326 -45 -561 -117 -854 -28 -115
-37 -167 -30 -174 6 -6 11 -16 11 -22 0 -5 -4 -3 -10 5 -5 8 -11 12 -13 10 -3
-3 -27 -70 -55 -150 -69 -197 -122 -325 -197 -477 -35 -70 -62 -129 -61 -131
14 -14 225 404 296 588 29 74 56 144 61 155 72 179 163 584 193 865 16 138 32
450 25 450 -3 0 -25 -21 -51 -47z"/>
<path d="M4055 2071 c-66 -16 -126 -74 -144 -138 -14 -50 -14 -377 0 -428 26
-95 102 -149 209 -150 122 0 208 69 218 175 l5 50 -57 0 c-53 0 -56 -1 -56
-25 0 -95 -126 -141 -187 -70 -22 25 -23 33 -23 231 l0 206 29 29 c66 66 181
18 181 -75 0 -25 3 -26 55 -26 l55 0 0 40 c0 129 -138 216 -285 181z"/>
<path d="M6997 2065 c-45 -15 -83 -48 -104 -88 -16 -33 -19 -120 -5 -157 16
-41 78 -95 161 -140 120 -64 146 -89 146 -139 0 -60 -30 -86 -99 -86 -67 0
-103 28 -112 87 l-7 38 -55 0 -55 0 5 -51 c11 -111 108 -184 233 -176 115 8
190 69 201 164 13 102 -25 152 -178 240 -122 70 -138 86 -138 139 0 56 33 84
97 84 59 0 103 -41 103 -94 l0 -36 55 0 55 0 0 39 c0 53 -15 89 -53 129 -54
55 -167 77 -250 47z"/>
<path d="M2026 2061 c-6 -10 -186 -680 -186 -693 0 -5 26 -8 57 -6 49 3 58 6
61 23 2 11 8 37 13 58 l10 37 109 0 108 0 10 -32 c6 -18 13 -45 16 -60 6 -27
9 -28 65 -28 l59 0 -28 108 c-53 202 -140 527 -150 565 l-11 37 -64 0 c-35 0
-66 -4 -69 -9z m109 -308 c21 -87 40 -164 43 -170 3 -10 -17 -13 -80 -13 -47
0 -87 2 -89 5 -6 6 74 335 82 335 3 0 22 -71 44 -157z"/>
<path d="M2437 2063 c-2 -5 -3 -26 -2 -48 1 -22 3 -178 3 -347 l2 -308 55 0
55 0 2 253 3 252 55 -92 c30 -51 61 -95 69 -98 15 -6 40 11 50 35 13 33 86
150 94 150 4 0 7 -112 7 -250 l0 -250 55 0 55 0 0 355 0 356 -57 -3 c-64 -3
-54 7 -157 -159 l-38 -62 -32 49 c-17 28 -48 78 -70 112 l-39 62 -53 0 c-29 0
-54 -3 -57 -7z"/>
<path d="M3077 2063 c-10 -9 -8 -676 2 -691 5 -9 25 -12 57 -10 l49 3 3 353 2
352 -53 0 c-30 0 -57 -3 -60 -7z"/>
<path d="M3320 1715 l0 -355 55 0 55 0 2 238 3 237 88 -180 c49 -99 100 -206
115 -237 l27 -58 58 0 57 0 0 355 0 355 -55 0 -55 0 -2 -236 -3 -237 -61 124
c-33 68 -85 175 -114 237 l-54 112 -58 0 -58 0 0 -355z"/>
<path d="M4460 1715 l0 -355 55 0 55 0 0 355 0 355 -55 0 -55 0 0 -355z"/>
<path d="M4700 1714 l0 -356 148 4 c132 3 151 6 189 27 88 50 103 98 103 324
0 199 -9 246 -59 296 -50 50 -96 61 -250 61 l-131 0 0 -356z m291 227 l29 -29
0 -195 0 -195 -29 -31 c-27 -29 -33 -31 -100 -31 l-71 0 0 255 0 255 71 0 c65
0 73 -2 100 -29z"/>
<path d="M5396 2053 c-3 -10 -23 -85 -45 -168 -22 -82 -56 -211 -76 -285 -21
-74 -40 -148 -45 -165 -4 -16 -10 -40 -14 -52 -7 -22 -5 -23 52 -23 l60 0 14
58 14 57 111 3 112 3 12 -61 12 -60 58 0 c33 0 59 2 59 5 0 8 -85 333 -138
525 l-47 175 -67 3 c-57 2 -67 0 -72 -15z m114 -298 c22 -86 40 -164 40 -171
0 -11 -19 -14 -85 -14 -51 0 -85 4 -85 10 0 19 83 341 87 337 2 -2 21 -75 43
-162z"/>
<path d="M5780 2020 l0 -50 85 0 85 0 0 -305 0 -305 55 0 55 0 0 305 0 305 90
0 90 0 0 50 0 50 -230 0 -230 0 0 -50z"/>
<path d="M6436 1893 c-26 -98 -67 -252 -92 -342 -24 -91 -44 -171 -44 -178 0
-9 16 -13 55 -13 l54 0 11 43 c6 23 13 50 16 60 5 15 19 17 114 17 95 0 109
-2 114 -17 3 -10 10 -37 16 -60 l11 -43 54 0 c36 0 55 4 55 12 0 7 -16 73 -36
148 -20 74 -47 173 -59 220 -13 47 -38 140 -56 208 l-33 122 -66 0 -67 0 -47
-177z m160 -154 c22 -88 38 -162 35 -164 -2 -3 -42 -5 -88 -5 -76 0 -84 2 -79
18 3 9 21 86 42 170 20 84 39 150 42 147 4 -4 25 -78 48 -166z"/>
<path d="M7412 1788 c3 -264 4 -286 24 -322 62 -120 239 -152 341 -63 71 62
73 73 73 387 l0 280 -55 0 -55 0 0 -275 0 -275 -29 -32 c-26 -29 -35 -33 -80
-33 -60 0 -88 22 -101 81 -5 21 -10 150 -10 287 l0 247 -55 0 -56 0 3 -282z"/>
<path d="M7980 1715 l0 -355 128 0 c153 0 213 15 260 63 73 75 68 220 -11 281
l-31 24 32 26 c63 54 70 175 15 244 -47 58 -94 71 -255 71 l-138 1 0 -355z
m291 226 c23 -23 29 -38 29 -71 0 -33 -6 -48 -29 -71 -27 -27 -35 -29 -100
-29 l-71 0 0 100 0 100 71 0 c65 0 73 -2 100 -29z m-18 -272 c34 -15 50 -50
50 -106 0 -39 -4 -50 -31 -75 -29 -25 -40 -28 -102 -28 l-70 0 -1 88 c0 48 -2
97 -3 110 -3 21 1 22 65 22 38 0 79 -5 92 -11z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
